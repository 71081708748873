import { render, staticRenderFns } from "./EditScheduleGroup.vue?vue&type=template&id=587b25a4&scoped=true&"
import script from "./EditScheduleGroup.vue?vue&type=script&lang=ts&"
export * from "./EditScheduleGroup.vue?vue&type=script&lang=ts&"
import style0 from "./EditScheduleGroup.vue?vue&type=style&index=0&lang=css&"
import style1 from "./EditScheduleGroup.vue?vue&type=style&index=1&id=587b25a4&scoped=true&lang=css&"
import style2 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587b25a4",
  null
  
)

export default component.exports