





























































































































































































import {
    Component,
    Vue,
	Watch
} from 'vue-property-decorator';
import {
    getComponent
} from '@/utils/helpers';
import wellTestModule from '@/store/modules/wellTestModule';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { Debounce } from 'vue-debounce-decorator';
import { DateTime } from 'luxon';


import workspaceModule from '@/store/modules/workspaceModule';
import AWS from 'aws-sdk';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		ComponentDetails: () => getComponent('schedule/ComponentDetails'),
		AddSchedule: () => getComponent('schedule/AddSchedule'),
		AddField: () => getComponent('schedule/AddField'),
		Multiselect,
    },
})

export default class ScheduleV2 extends mixins() {

	addJobIsLoading = false

	addJobGroupSelected = ''

	templateDetailsFieldIndexExpanded = []

  	addNewWorkspacePopupAddField = false

  	templateDetails = [
	  {
		  type: "Fields",
		  values: [
	
		  ]
	  },{
		  type: "Rules",
		  values: [
			  
		  ]
	  }
  ]


	get editGroupDetails() {
		return scheduleModule.editGroupDetails
	}

	get activeComponent() {
		return scheduleModule.activeComponent
	}


	get availableUsers() {
		return accountModule.reassignmentList.map((i) => ({
		// @ts-ignore
		text: i.name,
		value: i.email,
		}));
	}





    get enabledWells() {
        return assetsModule.enabledWells;
    }



    async created() {
		this.showEditRowPopup(scheduleModule.editGroupDetails)
    }



	showEditRowPopup(row) {
		var group = null
		// @ts-ignore
		for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
			// @ts-ignore
			if (row.title_row.selection_id == workspaceModule.activeTable.Groups[x].ID) {
				// @ts-ignore
				group = workspaceModule.activeTable.Groups[x]
			}
		}
		// @ts-ignore
		this.selectAddViewOption(group.Columns)
		
	}


	closeAddNewWorkspacePopupAddField() {
		this.addNewWorkspacePopupAddField = false
	}


  applyNewWorkspacePopupAddField(fieldSelection, fieldNameSelection) {
	  
	if (fieldSelection != '' && fieldNameSelection != '') {
		var local_icon = ''
		var local_value = ''
		var icon_type = 'MATERIAL'
		if (fieldSelection == "Tasq Date Range") {
			local_icon = "date_range"
			local_value = "DATE"
		} else if (fieldSelection == "Well") {
			local_value = "WELL"
			icon_type = "WELL"
		} else if (fieldSelection == "User") {
			local_icon = "person"
			local_value = "USER"
		} else if (fieldSelection == "Vendor") {
			local_icon = "engineering"
			local_value = "VENDOR"
		} else if (fieldSelection == "Cost") {
			local_icon = "attach_money"
			local_value = "COST"
		} else if (fieldSelection == "Tags") {
			local_icon = "tag"
			local_value = "TAGS"
		} else if (fieldSelection == "Text") {
			local_icon = "format_size"
			local_value = "TEXT"
		} else if (fieldSelection == "Date") {
			local_icon = "event"
			local_value = "DATE"
		} else if (fieldSelection == "File") {
			local_icon = "picture_as_pdf"
			local_value = "FILE"
		} else if (fieldSelection == "Checklist") {
			local_icon = "checklist"
			local_value = "CHECKLIST"
		} else if (fieldSelection == "Procedure") {
			local_icon = "task"
			local_value = "PROCEDURE"
		} else if (fieldSelection == "Email") {
			local_icon = "email"
			local_value = "EMAIL"
		} else if (fieldSelection == "Phone Number") {
			local_icon = "phone"
			local_value = "PHONE_NUMBER"
		} else if (fieldSelection == "Start Date") {
			local_icon = "event"
			local_value = "START_DATE"
		} else if (fieldSelection == "Due Date") {
			local_icon = "event"
			local_value = "DUE_DATE"
		} else if (fieldSelection == "Status") {
			local_icon = "task_alt"
			local_value = "STATUS"
		}

		this.templateDetails[0].values.push({
			// @ts-ignore
			title: fieldSelection,
			// @ts-ignore
			icon: local_icon,
			// @ts-ignore
			icon_type: icon_type,
			// @ts-ignore
			column_type: local_value,
			// @ts-ignore
			custom_name: fieldNameSelection,
		})
		this.addNewWorkspacePopupAddField = false
		if (!this.getTemplateDetailsFieldIndexExpanded(0)) {
			this.setTemplateDetailsFieldIndexExpandedIcon(0)
		}
	}

  }






	selectAddViewOption(columns) {
		var field_values: any[] = []
		field_values.push({
			title: "Add new field",
			icon_type: "ADD",
			column_type: ""
		})
		for (var x = 0; x < columns.length; x++) {
			field_values.push({
				title: columns[x].ColumnName,
				icon: this.getColumnTypeIcon(columns[x].ColumnType),
				icon_type: columns[x].ColumnType == "WELL" ? "WELL" : columns[x].ColumnType == "RIG" ? "RIG" : "MATERIAL",
				column_type: columns[x].ColumnType,
				custom_name: columns[x].ColumnName
			})
		}
		this.templateDetails = [
			{
				type: "Fields",
				// @ts-ignore
				values: field_values
			}
		]
	}



	getColumnTypeIcon(column_type) {
		if (column_type == "WELL") {
			return "task"
		} else if (column_type == "USER") {
			return "person"
		} else if (column_type == "VENDOR") {
			return "engineering"
		} else if (column_type == "CHECKLIST") {
			return "checklist"
		} else if (column_type == "FILE") {
			return "picture_as_pdf"
		} else if (column_type == "TESTERS") {
			return "quiz"
		} else if (column_type == "TASQ_DATE_RANGE") {
			return "date_range"
		} else if (column_type == "COST") {
			return "attach_money"
		} else if (column_type == "TAGS") {
			return "tag"
		} else if (column_type == "TEXT") {
			return "format_size"
		} else if (column_type == "DATE") {
			return "event"
		} else if (column_type == "START_DATE") {
			return "event"
		} else if (column_type == "DUE_DATE") {
			return "event"
		} else if (column_type == "PROCEDURE") {
			return "task"
		} else if (column_type == "EMAIL") {
			return "email"
		} else if (column_type == "PHONE_NUMBER") {
			return "phone"
		} else if (column_type == "STATUS") {
			return "task_alt"
		} else {
			return "task"
		}
	}



	async confirmEditRow() {

		this.addJobIsLoading = true
		var columns: any = []
		for (var r = 0; r < this.templateDetails[0].values.length; r++) {
			for (var f = 0; f < scheduleModule.columnMapping.length; f++) {
				// @ts-ignore
				if (this.templateDetails[0].values[r].column_type == scheduleModule.columnMapping[f].ColumnType) {
					columns.push({
						...scheduleModule.columnMapping[f],
						// @ts-ignore
						CustomName: this.templateDetails[0].values[r].custom_name
					})
					break
				}
			}
			
		}
	  await scheduleModule.updateGroupColumns({
		  // @ts-ignore
		  group_id: this.editGroupDetails.title_row.selection_id,
		  columns: JSON.stringify(columns)
	  })
	  this.addJobIsLoading = false
	  location.reload()

	}

	




	removeField(index) {
		this.templateDetails[0].values.splice(index, 1);
	}

	setTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
		// @ts-ignore
		if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
			// @ts-ignore
			const index = this.templateDetailsFieldIndexExpanded.indexOf(indexPassed);
			if (index > -1) {
				this.templateDetailsFieldIndexExpanded.splice(index, 1);
			}
		} else {
			// @ts-ignore
			this.templateDetailsFieldIndexExpanded.push(indexPassed)
		}
	}




	getTemplateDetailsFieldIndexExpanded(indexPassed) {
		// @ts-ignore
		if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
			return true
		}
		return false
	}



	getTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
		// @ts-ignore
		if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
			return "expand_more"
		}
		return "chevron_right"
	}



	editGroupHideClicked() {
		this.$emit('close-edit-group-details-popup')
		scheduleModule.setEditGroupDetails(null)
	}



}




